import React from "react";
import clsx from "clsx";
import { Wrapper, AppLink } from "@base";
import Icon from "./Icon";

const buttonSizes = {
  xs: "h-5 w-5",
  sm: "h-6 w-6",
  md: "h-7 w-7",
  lg: "h-8 w-8",
};

const textSizes = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-md",
  lg: "text-lg",
};

const iconSizes = {
  sm: "w-1",
  md: "w-2",
  lg: "w-3",
};

const ButtonAlt = ({
  children,
  className: _className,
  color = "blue",
  icon = "triangle",
  iconSize = "sm",
  onClick,
  reverse = false,
  size = "sm",
  to,
}) => {
  return (
    <div className={clsx("group inline-block", _className)}>
      <Wrapper
        condition={to}
        to={to}
        component={AppLink}
        tag="span"
        onClick={onClick}
        className="inline-flex items-center space-x-2"
        noDispatch
      >
        {/* back arrow */}
        {icon && reverse && (
          <div
            className={clsx(
              "flex items-center justify-center rounded-full duration-300",
              {
                "bg-white/50 hover:bg-orange group-hover:bg-orange":
                  color === "white",
                "bg-orange hover:bg-blue group-hover:bg-blue":
                  color?.includes("blue") || color === "orange",
                "bg-orange hover:bg-green group-hover:bg-green":
                  color === "green",
              },
              buttonSizes[size]
            )}
          >
            <Icon
              name={icon}
              className={clsx(
                "flex-shink-0 h-auto rotate-180 text-white",
                {
                  "mr-[2px]": icon === "triangle",
                },
                iconSizes[iconSize]
              )}
            />
          </div>
        )}
        {/* label */}
        <span
          className={clsx(
            "font-bold leading-normal",
            {
              "text-offwhite-lighter": color === "white",
              "text-blue": color?.includes("blue") || color === "orange",
              "text-green": color === "green",
            },
            textSizes[size]
          )}
        >
          {children}
        </span>
        {/* forward arrow */}
        {icon && !reverse && (
          <div
            className={clsx(
              "flex items-center justify-center rounded-full duration-300",
              {
                "bg-white/35 hover:bg-orange group-hover:bg-orange":
                  color === "white",
                "bg-orange hover:bg-blue group-hover:bg-blue":
                  color?.includes("blue") || color === "orange",
                "bg-orange hover:bg-green group-hover:bg-green":
                  color === "green",
              },
              buttonSizes[size]
            )}
          >
            <Icon
              name={icon}
              className={clsx(
                "flex-shink-0 h-auto w-1 text-white",
                {
                  "ml-[2px]": icon === "triangle",
                },
                iconSizes[iconSize]
              )}
            />
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default ButtonAlt;

import React from "react";
import clsx from "clsx";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag = "span",
}) => {
  let Tag = tag;
  let classes = "text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes = "font-heading text-4.5xl md:text-5.5xl leading-tighter";
      break;
    case "h2":
      Tag = "h2";
      classes = "font-heading text-3.5xl md:text-4.5xl leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-heading text-3xl md:text-4xl leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-heading text-2xl md:text-3xl leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-heading text-xl md:text-2xl leading-tighter";
      break;
    case "h6":
      Tag = "h6";
      classes = "font-heading text-lg md:text-xl leading-tighter";
      break;
    case "title":
      classes = "block font-heading text-base font-bold leading-tight";
      break;
    case "2xl":
      classes = "block text-2xl leading-tight font-sans";
      break;
    case "xl":
      classes = "block text-xl leading-tight font-sans";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-sm leading-normal font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-normal";
      break;
    case "label":
      classes = "block text-xs font-bold leading-tight uppercase tracking-0.5";
      break;
    case "crumb":
      classes = "inline-block text-base md:text-sm font-bold leading-tight";
      break;
    case "caption":
      classes = "inline-block text-sm leading-tight";
      break;
    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={clsx(classes, _className, {
            quotes,
            "prose prose-sm md:prose-base": richText,
          })}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={clsx(classes, _className, {
          quotes,
          "prose prose-sm md:prose-base": richText,
        })}
      >
        {child}
      </Tag>
    );
  });
};

export default Text;

import React from "react";

const EmbedCode = ({ code: _code }) => {
  if (_code) {
    // standardize urls + strip duplicitous code
    const code = _code
      .replace(`<script async src="//www.instagram.com/embed.js"></script>`, "")
      .replace(
        `<script async="" src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        ""
      )
      .replace("https://", "//")
      .replace("http://", "//")
      .replace("//", "https://")
      .replace("<iframe ", `<iframe title="social media embed" `);
    if (typeof window !== "undefined") {
      return (
        <div className="embedCode">
          <div
            className="flex justify-center"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: code }}
          />
        </div>
      );
    }
  }
  return <div />;
};

export default EmbedCode;
